import React from 'react';
import { func, object } from 'prop-types';
import { Box, Typography, Container } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { propOr } from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry, faHardHat } from '@fortawesome/free-solid-svg-icons';
import { Event as EventIcon } from '@material-ui/icons';
import { costCenterString } from 'utils/tempWorkers';

import { StyledRating } from 'components';
import { getRank, isNotEmpty, useJobLabel, useLastContractEnd } from '../common/common';

/* const useStyles = makeStyles((theme) => ({
  box: {
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    backgroundColor: theme.palette.light.main
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  button: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
})); */

const WorkerDetailMobile = ({ workerData, onAskContract }) => {
  // const classes = useStyles();
  const { t, i18n } = useTranslation();
  const jobFunctionLabel = useJobLabel(workerData, i18n);
  const lastContractEnd = useLastContractEnd(workerData);
  const rank = getRank(workerData);
  const theme = useTheme();
  const tmp = onAskContract;
  // TO REMOVE (bypass commit check...)
  let a = 1;
  if (tmp && t) {
    a += 1;
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" key={a}>
      <Box flex="1" overflow="auto">
        <Container maxWidth="md">
          <Box p={4} width={[1, 1, 2 / 3]} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h6" component="h2" textAlign="center">
              {propOr('', 'fullname', workerData)}
            </Typography>
            <Box>{isNotEmpty(rank) && <StyledRating name="workerRating" size="small" readOnly value={rank} />}</Box>
          </Box>
          <Box width={[1, 1, 2 / 3]} display="flex" flexDirection="column" alignItems="left">
            <Box mb={3}>
              <Typography variant="body1" textAlign="center">
                {t('myWorkers:lastContract')}
              </Typography>
            </Box>
            <Box ml={1}>
              <Box display="flex" flexDirection="row" alignItems="center" mb={3}>
                <FontAwesomeIcon icon={faHardHat} size="2x" color={theme.palette.text.secondary} />
                <Box ml={6}>
                  <Typography variant="body2">{jobFunctionLabel}</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center" mb={3}>
                <FontAwesomeIcon icon={faIndustry} size="2x" color={theme.palette.text.secondary} />
                <Box ml={6}>
                  <Typography variant="body2">
                    {costCenterString(workerData?.lastContract?.costCenter, workerData?.lastContract?.customer)}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <EventIcon color="secondary" />
                <Box ml={6}>{lastContractEnd && <Typography variant="body2">{lastContractEnd}</Typography>}</Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      {/* <Box display="flex" justifyContent="flex-end" className={classes.box} py={4}>
        <Container maxWidth="xl" className={classes.container}>
          <Button
            className={classes.button}
            width="100%"
            type="submit"
            name="confirm"
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
              onAskContract(workerData);
            }}>
            {t('myWorkers:askForContract')}
          </Button>
        </Container>
      </Box> */}
    </Box>
  );
};

WorkerDetailMobile.propTypes = {
  workerData: object,
  onAskContract: func
};

WorkerDetailMobile.defaultProps = {
  workerData: {},
  onAskContract: () => {}
};

export default WorkerDetailMobile;
