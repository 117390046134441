import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bool, object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { eventGA } from 'utils/analytics';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Button, Fab, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import NewContractHandler from 'components/NewContractHandler/NewContractHandler';
import NewDemandHandler from 'components/NewDemandHandler/NewDemandHandler';
import NewPayroll from '../../NewPayroll/NewPayroll';
import Modal from '../../Modal/Modal';

const RequestMenu = ({ classes, isMobile, onClose }) => {
  const { t } = useTranslation(['tempWorkers', 'helpers']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newTempWorkerOpen, setNewTempWorkerOpen] = useState(false);
  const [newPayrollOpen, setNewPayrollOpen] = useState(false);
  const [newContractOpen, setNewContractOpen] = useState(false);
  const onNewTempWorkerClick = () => {
    eventGA('home_new_order');
    setNewTempWorkerOpen(true);
    setAnchorEl(null);
  };
  const onNewPayRollClick = () => {
    eventGA('home_new_payroll');
    setNewPayrollOpen(true);
    setAnchorEl(null);
  };

  return (
    <>
      {!isMobile ? (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={(event) => setAnchorEl(event.currentTarget)}>
          {t('tempWorkers:newDemand')}
        </Button>
      ) : (
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={(event) => setAnchorEl(event.currentTarget)}>
          <AddIcon />
        </Fab>
      )}
      <Modal
        isOpen={newTempWorkerOpen}
        onClose={(res) => {
          setNewTempWorkerOpen(false);
          onClose(res);
        }}>
        <NewDemandHandler actionType="create" />
      </Modal>
      <Modal
        isOpen={newPayrollOpen}
        onClose={(res) => {
          setNewPayrollOpen(false);
          onClose(res);
        }}>
        <NewPayroll shouldOpenContract={(val) => setNewContractOpen(val)} />
      </Modal>
      <Modal
        isOpen={newContractOpen}
        onClose={(res) => {
          setNewContractOpen(false);
          onClose(res);
        }}>
        <NewContractHandler actionType="create" />
      </Modal>
      <Menu
        id="simple-menu"
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={onNewTempWorkerClick}>
          <ListItemIcon className={classes.menuIcon}>
            <FontAwesomeIcon icon={faUserPlus} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={t('tempWorkers:actions.order')} />
        </MenuItem>
        <MenuItem onClick={onNewPayRollClick}>
          <ListItemIcon className={classes.menuIcon}>
            <FontAwesomeIcon icon={faUserPlus} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: 'body2' }}
            primary={t('tempWorkers:actions.recordPayroll')}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

RequestMenu.propTypes = {
  classes: object.isRequired,
  isMobile: bool,
  onClose: func
};

RequestMenu.defaultProps = {
  isMobile: false,
  onClose: () => null
};

const styles = createStyles(() => ({
  fab: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    zIndex: 999
  },
  menuIcon: {
    minWidth: '24px',
    marginRight: '6px'
  }
}));

const mapDispatch = ({ contracts: { dumpCandidate } }) => ({
  dumpCandidate
});

export default withStyles(styles)(connect(null, mapDispatch)(RequestMenu));
